import * as React from "react"
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

import Layout from "../components/layout"
import Seo from "../components/seo"
import SokoliImage from "../components/image"
import SokoliRow from "../components/row"
import SokoliGrowItem from "../components/growitem"
import CardPage from "./card"

const IndexPage = () => (
  <CardPage></CardPage>
  // <Layout>
  //   <Seo title="Home" />
  //   <SimpleReactLightbox>
  //     <SRLWrapper options={{
  //       settings: {
  //         downloadedFileName: 'sokoli.co',
  //         hideControlsAfter: 1,
  //       },
  //       buttons: {
  //         showAutoplayButton: false,
  //         showDownloadButton: false,
  //         showFullscreenButton: false,
  //         showThumbnailsButton: false,
  //       },
  //       thumbnails: {
  //         showThumbnails: false
  //       },
  //       caption: {
  //         showCaption: false
  //       }
  //     }} >
  //       <div className="box-border mx-6 lg:mx-16 flex flex-col gap-y-12 lg:gap-y-20 items-center">
  //         <SokoliRow>
  //           <SokoliImage img_name="DSC00840.jpg" className="lg:w-p1" />
  //           <SokoliGrowItem />
  //           <SokoliImage img_name="DSC04598.jpg" className="lg:w-p2" hor={true} />
  //         </SokoliRow>
  //         <SokoliRow>
  //           <SokoliGrowItem className="basis-2" />
  //           <SokoliImage img_name="DSC09443.jpg" className="lg:w-p3" hor={true} />
  //           <SokoliGrowItem className="basis-3" />
  //           <SokoliImage img_name="DSC09798.jpg" className="lg:w-p4" />
  //           <SokoliGrowItem />
  //         </SokoliRow>
  //         <SokoliRow>
  //           <SokoliImage img_name="DSC02893.jpg" />
  //         </SokoliRow>
  //         <SokoliRow>
  //           <SokoliImage img_name="DSC00916.jpg" className="lg:w-p6" hor={true} />
  //           <SokoliGrowItem />
  //           <SokoliImage img_name="DSC00094.jpg" className="lg:w-p7" />
  //         </SokoliRow>
  //         <SokoliRow>
  //           <SokoliImage img_name="DSC01176.jpg" hor={true} />
  //         </SokoliRow>
  //         <SokoliRow>
  //           <SokoliImage img_name="DSC00111.jpg" className="lg:w-p9p11 self-start" />
  //           <SokoliGrowItem />
  //           <SokoliImage img_name="DSC00964-2.jpg" className="lg:w-p10" />
  //           <SokoliGrowItem />
  //           <SokoliImage img_name="DSC02781.jpg" className="lg:w-p9p11 self-end" />
  //         </SokoliRow>
  //         <SokoliRow>
  //           <SokoliGrowItem />
  //           <SokoliImage img_name="DSC04685.jpg" className="lg:w-p12" hor={true} />
  //           <SokoliGrowItem />
  //         </SokoliRow>
  //         <SokoliRow>
  //           <SokoliImage img_name="DSC04043.jpg" className="lg:w-p13 self-end" />
  //           <SokoliGrowItem />
  //           <SokoliImage img_name="DSC02914.jpg" className="lg:w-p14" />
  //           <SokoliGrowItem />
  //           <SokoliImage img_name="DSC03047.jpg" className="lg:w-p15 self-start" />
  //         </SokoliRow>
  //         <SokoliRow>
  //           <SokoliImage img_name="DSC00084.jpg" className="lg:w-p16 self-start" hor={true} />
  //           <SokoliGrowItem />
  //           <SokoliImage img_name="DSC00922.jpg" className="lg:w-p17" hor={true} />
  //         </SokoliRow>
  //         <SokoliRow>
  //           <SokoliImage img_name="DSC02760.jpg" className="lg:w-p18p19" />
  //           <SokoliGrowItem />
  //           <SokoliImage img_name="DSC02924.jpg" className="lg:w-p18p19" />
  //         </SokoliRow>
  //         <SokoliRow>
  //           <SokoliImage img_name="DSC00955.jpg" className="lg:w-p20 self-start" hor={true} />
  //           <SokoliGrowItem />
  //           <SokoliImage img_name="DSC03026.jpg" className="lg:w-p21p22" />
  //           <SokoliGrowItem />
  //           <SokoliImage img_name="DSC01032.jpg" className="lg:w-p21p22" />
  //         </SokoliRow>
  //         <SokoliRow>
  //           <SokoliImage img_name="DSC03957.jpg" className="lg:w-p23" />
  //           <SokoliGrowItem />
  //           <SokoliImage img_name="DSC04097.jpg" className="lg:w-p24 self-end" />
  //         </SokoliRow>
  //       </div>
  //     </SRLWrapper>
  //   </SimpleReactLightbox>
  // </Layout>
)

export default IndexPage
